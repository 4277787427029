<template>
  <v-container id="clients" class="ma-auto pa-0" fluid>
    <v-row class="pa-0 ma-0 main-modified">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="pa-0 ma-0" :style="{'background-image': 'url(' + require('../../assets/images/clients/clients-bg.png') + ')'}" style="height:300px;background-repeat: no-repeat;background-size: cover;">
          <v-col class="text-center ma-auto">
            <h1 class="text-h4 custom-secondary--text text-uppercase" style="-webkit-text-stroke: 0.2px white;">{{
              $t("clients")
            }}</h1>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mt-4">
          <v-col cols="12" class="text-h5 text-center custom-accent--text text-uppercase">{{$t("thesuccessstories")}}</v-col> 
        </v-row>
        <transition name="fade" mode="out-in" v-if="loading">
          <v-row class="px-8 ma-0" justify="center" :key="itemsperpage * page">
            <v-col
              v-for="i in itemsperpage"
              :key="i"
              cols="12"
              sm="10"
              md="10"
              lg="10"
            >
              <v-skeleton-loader
                type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="clients.length==0 && !loading">
          <v-row class="text-center pa-12 ma-auto my-7" ><i class="ma-auto text-h4 text--secondary">{{$t("noresult")}}.</i></v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="clients.length && !loading">
          <v-row class="px-8 ma-0" justify="center" :key="itemsperpage * page">
            <v-col
              v-for="(item, i) in clients.slice(
                itemsperpage * (page - 1),
                itemsperpage * page
              )"
              :key="i"
              cols="12"
              sm="10"
              md="10"
              lg="10"
            >
              <v-card
                class="elevation-0"
                outlined
              >
                <v-row class="pa-auto ma-auto values-cards">
                  <v-col cols="12" md="5" class="pa-4 ma-auto">
                    <v-img
                        :src="require('../../assets/'+item.image)"
                        :alt="item.alt"
                        aspect-ratio="1"
                        max-height="70"
                        contain
                      >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                  <v-col cols="12" md="7" class="px-4 ma-auto">
                    <v-card-title class="mb-5">
                      <span>{{$i18n.locale == "en" ? item.titleEN : item.titleFR}}</span>
                    </v-card-title>
                    <v-card-subtitle>
                      <span>{{$i18n.locale == "en"? item.descriptionEN: item.descriptionFR}}</span>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </transition>
        <v-row class="pa-0 ma-0 mb-4">
          <v-col v-if="Math.ceil(clients.length / itemsperpage)>1">
            <v-pagination
              v-model="page"
              :length="Math.ceil(clients.length / itemsperpage)"
              color="custom-accent"
              :total-visible="7"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
        <v-row data-aos="fade-down" class="custom-accent white--text pa-0 ma-0" id="contact">
          <v-col cols="12" sm="10" md="7" lg="5" class="text-center ma-auto pa-12">
            <div class="text-h6 text-uppercase">{{$t("interested")}}</div>
            <v-btn class="my-6" @click="$navigateToPath('/contact')" dark depressed rounded>{{$t("contact")}} {{$t("now")}}!<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "Clients",

  data() {
    return {
      page: 1,
      itemsperpage: 5,
    };
  },
  components: {
  },
  async created() {
    await this.retrieveClients();
  },
  computed: {
    ...mapGetters('client', {
      clients:'getClients'
    }),
    ...mapState("client", {
      loading: "loading"
    }),
  },
  watch: {
    page(){
      this.$root.$emit('toTop');
    },
  },
  methods: {
    
    ...mapActions("client", ["addClient","retrieveClients"]),
  },
};
</script>
<style scoped>
@import url("../../assets/styles/clients.css");
</style>